<template>
  <div>
    <pcHeaders></pcHeaders>
    <div class="flex"
         style="justify-content: center">
      <div class="banners">
        <div class="pay_methods_header flex"
             style="justify-content: space-between">
          <div style="display: flex">
            <div style="height: 50px">
              <img style=""
                   src="../../assets/images/pay_03.png"
                   alt="" />
            </div>

            <div style="font-size: 16px; margin-left: 15px; margin-top: 3px">
              <div>订单提交成功，请尽快付款！</div>
              <div>订单编号：{{ orderInfo.order.ordNo }}</div>
            </div>
          </div>

          <div style="font-size: 16px; margin-left: 15px; margin-top: 3px">
            <div v-if="!showBox">剩余支付时间：<span style="color: #ffa45d"> {{ hours }}小时{{ minutes }}分{{ seconds }}秒</span> </div>
            <div style="text-align: right">应付金额 : <span style="font-size: 18px; color: #f02311; font-weight: bold">￥{{ orderInfo.order.ordMoneysum }}</span>元</div>
          </div>
        </div>

        <div class="flex"
             style="font-size: 16px; justify-content: space-between; margin-top: 30px">
          <div class="flex">
            <div>
              <img style="margin-top: 2px"
                   src="../../assets/images/02_03.png"
                   alt="" />
            </div>
            <div style="margin-left: 5px; font-size: 18px">支付方式</div>
          </div>
        </div>

        <div class="pay_methods_header flex"
             style="
            justify-content: space-between;
            padding: 0 30px;
            background: #fff;
            border: 1px solid #f3f3f3;
            margin-top: 10px;
            -webkit-box-shadow: 3px 3px 3px #e0e0e0;
            -moz-box-shadow: 3px 3px 3px #e0e0e0;
            box-shadow: 3px 3px 3px #e0e0e0;
            align-items: center;
          ">
          <div style="display: flex; align-items: center">
            <div>
              <el-radio v-model="radio"
                        label="1"
                        style="align-items: center; display: flex">
                <img src="../../assets/images/pay1_05.png"
                     alt="" />
              </el-radio>
            </div>
            <div style="width: 1px;height: 15px;background: #E0E0E0;margin-left: 50px;">

            </div>
            <el-radio v-model="radio"
                      style="align-items: center; display: flex;margin-left: 50px;"
                      label="2"><img src="../../assets/images/pay1_03.png"
                   alt="" /></el-radio>

            <div v-if="!showBox"
                 style="width: 1px;height: 15px;background: #E0E0E0;margin-left: 50px;">

            </div>

            <el-radio v-if="!showBox"
                      v-model="radio"
                      style="align-items: center; display: flex;margin-left: 50px;"
                      label="3"><img src="../../assets/images/pay2_03.png"
                   alt="" /></el-radio>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 35px;">
          <div></div>
          <div class="pay_btn"
               @click="pay">确认支付</div>
        </div>
      </div>

    </div>

    <el-dialog title="温馨提示："
               :visible.sync="payShow"
               width="30%">
      <div style="text-align: center;font-size: 18px;">是否支付成功？</div>
      <div style="text-align: center;color: #E33737;">(支付完成前，请不要关闭此支付验证窗口)</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="payShow = false">否</el-button>
        <el-button type="primary"
                   @click="dz">是</el-button>
      </span>
    </el-dialog>

    <el-dialog title="请输入卡号"
               :visible.sync="cardShow"
               width="30%">

      <el-form :model="cardInfo"
               :rules="rules"
               ref="ruleForm"
               label-width="100px"
               class="demo-ruleForm">

        <el-form-item label="学习卡号"
                      prop="cardNo">
          <el-input v-model="cardInfo.cardNo"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="cardShow = false">取 消</el-button>
          <el-button type="primary"
                     @click="submitForm('ruleForm')">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';

import {
  toPayOrderByCard,
  toPayOrder,
  payEnd,
  payEnd3,
  toTradeQueryZFB,
  toTradeQueryWX,
  toTradeQueryWX2,
  toTradeQueryZFB2,
  getExpressDetail
} from '@/api/pay.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      orderInfo: {
        order: {
          ordNo: ''
        }
      }, //订单核对数据
      radio: '1',
      totalSeconds: 0,
      imerId: null, // 存放定时器ID
      payShow: false,
      cardShow: false,
      cardInfo: {},
      rules: {
        cardNo: [{ required: true, message: '请输入学习卡号', trigger: 'blur' }]
      },
      showBox: false
    };
  },
  // 监听属性 类似于data概念
  computed: {
    hours() {
      const h = Math.floor(this.totalSeconds / (60 * 60));
      if (h >= 24) {
        this.stopCountdown();
      }
      return h;
    },
    minutes() {
      const m = Math.floor((this.totalSeconds % (60 * 60)) / 60);
      return m > 59 ? '59' : m;
    },
    seconds() {
      const s = Math.floor(this.totalSeconds % 60);
      return s > 59 ? '59' : s;
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //弹框对账
    dz() {
      if (this.showBox) {
        //运单的对账
        if (this.radio == 1) {
          this.toTradeQueryZFB2();
        } else {
          this.toTradeQueryWX2();
        }
        return false;
      }
      if (this.radio == 1) {
        this.toTradeQueryZFB();
      } else {
        this.toTradeQueryWX();
      }
    },
    //学习卡支付
    submitForm() {
      this.cardInfo.orderId = this.orderInfo.order.id;
      toPayOrderByCard(this.cardInfo).then(res => {
        console.log(res.data.data);
        if (res.data.data.result !== '2') {
          this.$message(res.data.data.msg);
          return false;
        } else {
          this.$router.push({
            path: '/paySuccess',
            query: {
              orderId: this.orderInfo.order.id
            }
          });
        }
      });
    },
    //支付宝对账
    toTradeQueryZFB() {
      toTradeQueryZFB({ orderId: this.orderInfo.order.id }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '2') {
          this.$confirm('支付失败', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.payShow = false;
          });
        } else if (res.data.data.result == '1') {
          this.$router.replace({
            path: '/paySuccess',
            query: {
              orderId: this.orderInfo.order.id
            }
          });
        }
      });
    },
    //微信对账
    toTradeQueryWX() {
      toTradeQueryWX({ orderId: this.orderInfo.order.id }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '2') {
          this.$confirm('支付失败', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.payShow = false;
          });
        } else {
          this.payShow = false;
          this.$message({
            message: '支付成功！',
            type: 'success'
          });
          this.$router.push('/studyMain');
        }
      });
    },
    //运单支付宝对账
    toTradeQueryZFB2() {
      toTradeQueryZFB2({ expressId: this.orderInfo.order.id }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '2') {
          this.$confirm('支付失败', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.payShow = false;
          });
        } else if (res.data.data.result == '1') {
          this.payShow = false;
          this.$message({
            message: '支付成功！',
            type: 'success'
          });
          this.$router.replace({
            path: '/studyMain',
          });
        } else {
          this.payShow = false;
          this.$message('系统错误')
        }
      });
    },
    //运单微信对账
    toTradeQueryWX2() {
      toTradeQueryWX2({ expressId: this.orderInfo.order.id }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '2') {
          this.$confirm('支付失败', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.payShow = false;
          });
          return false;
        } else {
          this.payShow = false;
          this.$message({
            message: '支付成功！',
            type: 'success'
          });
          this.$router.push('/studyMain');
        }
      });
    },
    startCountdown() {
      this.timerId = setInterval(() => {
        --this.totalSeconds;
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timerId);
    },
    //立即支付
    pay() {
      if (this.radio == 3) {
        this.cardShow = true;
        return false;
      }
      //运单支付
      if (this.showBox) {
        let payData = {
          userId: this.orderInfo.userId,
          expressId: this.orderInfo.order.expressId,
          payType: this.radio == 1 ? '06' : '0903'
        };
        payEnd3(payData).then(res => {
          if (res.data.code !== 200) {
            this.$message('接口异常，服务错误！');
            return false;
          }
          if (payData.payType == '06') {
            if (res.data.data.result == '22') {
              let routeData = this.$router.resolve({
                path: '/zfb',
                query: {
                  data: res.data.data.sHtmlText
                }
              });
              window.open(routeData.href, '_blank');
              this.payShow = true;
            } else {
              this.$message(res.data.data.err);
            }
          } else if (payData.payType == '0903') {
            if (res.data.data.result == '23') {
              let routeData = this.$router.resolve({
                path: '/vx',
                query: {
                  codeUrl: res.data.data.codeUrl,
                  expressId: res.data.data.expressId,
                  yd: 1
                }
              });
              window.open(routeData.href, '_blank');
              this.payShow = true;
            } else {
              this.$message(res.data.data.err);
            }
          } else {
          }
        });
        return false;
      }
      let payData = {
        userId: this.orderInfo.userId,
        orderId: this.orderInfo.order.id,
        ordPaytype: this.radio == 1 ? '06' : '0903'
      };
      this.$confirm('一旦缴费成功，正常情况下不允许退款，继续请点击确定，终止请点击取消', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          payEnd(payData).then(res => {
            if (res.data.data.code == '1' && payData.ordPaytype == '06') {
              let routeData = this.$router.resolve({
                path: '/zfb',
                query: {
                  data: res.data.data.sHtmlText
                }
              });
              window.open(routeData.href, '_blank');
              this.payShow = true;
            } else if (res.data.data.code == '1' && payData.ordPaytype == '0903') {
              let routeData = this.$router.resolve({
                path: '/vx',
                query: {
                  codeUrl: res.data.data.codeUrl,
                  orderNo: res.data.data.orderNo,
                  orderId: this.orderInfo.order.id
                }
              });
              window.open(routeData.href, '_blank');
              this.payShow = true;
            } else {
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    //生成订单
    toPayOrder() {
      toPayOrder({ orderId: this.$route.query.orderId }).then(res => {
        this.orderInfo = res.data.data;
        console.log(this.orderInfo.hour);
        this.totalSeconds = Number(this.orderInfo.hour) * 60 * 60 + Number(this.orderInfo.min) * 60 + Number(this.orderInfo.s);
        this.startCountdown();
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.$route.query.ydId);
    if (this.$route.query.ydId) {
      getExpressDetail({ expressId: this.$route.query.expressId }).then(res => {
        console.log(res.data);
        this.orderInfo = {
          userId: this.$route.query.userId,
          order: {
            id: res.data.data.id,
            ordNo: res.data.data.expressNo,
            ordMoneysum: res.data.data.expressPrice,
            expressId: res.data.data.id
          }
        };
      });

      this.showBox = true;
      return false;
    }
    if (this.$route.query.orderId) {
      this.toPayOrder();
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */

.flex {
  display: flex;
}
.banners {
  background: #fff;
  padding: 80px 0 20px 0;
  width: 1200px;
  font-family: Regular;
  padding-bottom: 200px;
  .pay_methods_header {
    height: 93px;
    border: 2px solid #e3e3e3;
    width: 100%;
    padding: 20px;
  }
  .pay_btn {
    width: 121px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #fd9d57;
    color: #fff;
    cursor: pointer;
  }
}
* {
  font-family: Regular;
}
</style>
